import React, { Fragment, useEffect, useState } from "react";
import { Col, Card } from "reactstrap";
import DataTable from "react-data-table-component";
import { Spinner } from "../../../AbstractElements";
import axios from "axios";
import { API } from "../../../api";

const Table = () => {
  const tableColumns = [
    {
      name: "Usuario",
      selector: (row) => `${row.user_id?.document ?? '0'} ${row.user_id?.name ?? 'Administrador'}`,
      sortable: true,
      center: false,
      width: '400px'
    },
    {
      name: "Valor",
      selector: (row) => `${row.value}`,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Cantidad de entradas",
      selector: (row) => `${row.ticket_amount}`,
      sortable: true,
      center: true,
      width: '200px'
    },
    {
      name: "Estado",
      selector: (row) => `${row.state}`,
      sortable: true,
      center: true,
    },
    {
      name: "Id mercadopago",
      selector: (row) => `${row.payment_id}`,
      sortable: true,
      center: true,
    },
    {
      name: "Fecha de creación",
      selector: (row) => `${row.createdAt}`,
      sortable: true,
      center: true,
    },
    {
      cell: (row) => (
        <a
          className="btn btn-info"
          href={`https://download.cantabriaticket.com/pdf/${row.id}`}
          target="_blank"
        >
          <i className="fa fa-download"></i>
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState([]);
  const [infoCopy, setInfoCopy] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(`${API}/dashboard/payments`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            setInfo(data.data);
            setInfoCopy(data.data);
          } else {
            alert(data.error);
          }
          setIsLoading(false);
        });
    } catch (error) {
      alert(error);
    }
  };

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
      setResetPaginationToggle(!resetPaginationToggle);
      setInfo(infoCopy);
    }
  };

  const getSubHeaderComponent = () => {
    return (
      <div class="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Documento o Nombre"
          onChange={(e) => {
            let newFilterText = e.target.value;
            if (!newFilterText) {
              setInfo(infoCopy);
              return;
            }
            console.log(info, newFilterText);
            const _info = info.filter(
              (row) =>
                row?.user_id?.name
                  ?.toLowerCase()
                  .includes(newFilterText?.toLowerCase()) ||
                row?.user_id?.document
                  ?.toString()
                  ?.toLowerCase()
                  .includes(newFilterText?.toLowerCase())
            );
            setInfo([..._info]);
            setFilterText(newFilterText);
          }}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          id="button-addon2"
          onClick={handleClear}
        >
          X
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          {isLoading ? (
            <div className="loader-box d-flex justify-center align-items-center">
              <Spinner attrSpinner={{ className: "loader-2" }} />
            </div>
          ) : (
            <>
              <DataTable
                data={info}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={getSubHeaderComponent()}
              />
            </>
          )}
        </Card>
      </Col>
    </Fragment>
  );
};

export default Table;
