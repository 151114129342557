// dashbaord
import Default from "../Components/Dashboard/Default";
import Discounts from "../Components/Dashboard/Discounts";
import Event from "../Components/Dashboard/Event";
import Purchases from "../Components/Dashboard/Purchases";
import Reserves from "../Components/Dashboard/Reserves";
import Tickets from "../Components/Dashboard/Tickets";
import Users from "../Components/Dashboard/Users";
import ReadQR from "../Components/Dashboard/ReadQR";
import BuyTicket from "../Components/Dashboard/BuyTickets";
import Provider from "../Components/Provider";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Default /> },
  { path: `${process.env.PUBLIC_URL}/readqr`, Component: <ReadQR /> },
  { path: `${process.env.PUBLIC_URL}/buy`, Component: <Provider><BuyTicket /></Provider> },
  { path: `${process.env.PUBLIC_URL}/seller`, Component: <Tickets /> },
  { path: `${process.env.PUBLIC_URL}/tickets`, Component: <Tickets /> },
  { path: `${process.env.PUBLIC_URL}/purchases`, Component: <Purchases /> },
  { path: `${process.env.PUBLIC_URL}/users`, Component: <Users /> },
  { path: `${process.env.PUBLIC_URL}/reserves`, Component: <Reserves /> },
  { path: `${process.env.PUBLIC_URL}/discounts`, Component: <Discounts /> },
  { path: `${process.env.PUBLIC_URL}/event`, Component: <Event /> }
];
