import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { LogIn } from "react-feather";
import { UL, LI } from "../../../AbstractElements";
import { Col } from "reactstrap";

const RightHeader = () => {
  const history = useNavigate();

  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.removeItem("authenticated");
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <Col
        xxl="7"
        xl="6"
        md="7"
        className="nav-right pull-right right-header col-8 p-0 ms-auto"
      >
        {/* <Col md="8"> */}
        <UL attrUL={{ className: "simple-list nav-menus flex-row" }}>
          <LI attrLI={{ onClick: Logout }}>
            <LogIn />
            <span style={{ cursor: "pointer" }}> Cerrar sesión </span>
          </LI>
        </UL>
        {/* </Col> */}
      </Col>
    </Fragment>
  );
};

export default RightHeader;
