import "react-tooltip/dist/react-tooltip.css";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import "./../../assets/jquery.seat-charts.min.js";

import { Breadcrumbs, P, Spinner } from "./../../AbstractElements";
import { API } from "../../api";
import Provider, { useMyContext } from "../Provider.jsx";
import * as numeral from "numeral";

const NumberPoint = ({ value }) => (
  <span>{numeral(value).format("0,0").replace(/,/g, ".")}</span>
);

const BuyTicket = () => {
  let _tickets = [];
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState();

  const [mapLI, setMapLI] = useState();
  const [mapLC, setMapLC] = useState();
  const [mapLD, setMapLD] = useState();
  const [mapBI, setMapBI] = useState();
  const [mapBC, setMapBC] = useState();
  const [mapBD, setMapBD] = useState();
  const [document, setDocument] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const [tickets, setTickets] = useMyContext();

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (info) {
      makeTicketsBuy(info);
    }
  }, [info]);

  const getInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${API}/payment/tickets/manually`);
      setInfo(data?.data);
    } catch (ex) {
      Swal.fire({
        icon: "error",
        text: ex.toString(),
      });
    }
    setIsLoading(false);
  };

  const makeTicketsBuy = (data) => {
    const scli = $("#seat-map-li").seatCharts({
      map: [
        "___________aaaaaa",
        "__________aaaaaaa",
        "_________aaaaaaaa",
        "_________aaaaaaaa",
        "________aaaaaaaaa",
        "_______aaaaaaaaaa",
        "_______aaaaaaaaaa",
        "______aaaaaaaaaaa",
        "______aaaaaaaaaaa",
        "_____aaaaaaaaaaaa",
        "____aaaaaaaaaaaaa",
        "___aaaaaaaaaaaaaa",
        "___aaaaaaaaaaaaaa",
        "__aaaaaaaaaaaaaaa",
        "__aaaaaaaaaaaaaaa",
        "_aaaaaaaaaaaaaaaa",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "LL",
          "M",
          "N",
          "Ñ",
        ],
        columns: [
          "17",
          "16",
          "15",
          "14",
          "13",
          "12",
          "11",
          "10",
          "9",
          "8",
          "7",
          "6",
          "5",
          "4",
          "3",
          "2",
          "1",
        ],
      },
      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "LUNETA IZQUIERDA",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) =>
              e?.seat == this.settings.id && e?.tribune == "LUNETA IZQUIERDA"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([..._tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapLI(scli);

    const sclc = $("#seat-map-lc").seatCharts({
      map: [
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "LL",
          "M",
          "N",
          "Ñ",
        ],
        columns: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
        ],
      },

      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "LUNETA CENTRO",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) => e?.seat == this.settings.id && e?.tribune == "LUNETA CENTRO"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([...__tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapLC(sclc);

    const scld = $("#seat-map-ld").seatCharts({
      map: [
        "aaaaaa___________",
        "aaaaaaa__________",
        "aaaaaaaa_________",
        "aaaaaaaa_________",
        "aaaaaaaaa________",
        "aaaaaaaaaa_______",
        "aaaaaaaaaa_______",
        "aaaaaaaaaaa______",
        "aaaaaaaaaaa______",
        "aaaaaaaaaaaa_____",
        "aaaaaaaaaaaaa____",
        "aaaaaaaaaaaaaa___",
        "aaaaaaaaaaaaaa___",
        "aaaaaaaaaaaaaaa__",
        "aaaaaaaaaaaaaaa__",
        "aaaaaaaaaaaaaaaa_",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "LL",
          "M",
          "N",
          "Ñ",
        ],
        columns: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
        ],
      },

      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "LUNETA DERECHA",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) => e?.seat == this.settings.id && e?.tribune == "LUNETA DERECHA"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([...__tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapLD(scld);

    const scbi = $("#seat-map-bi").seatCharts({
      map: [
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaa_",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "O",
          "P",
          "Q",
          "R",
          "RR",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "BB",
          "CC",
          "DD",
        ],
        columns: [
          "17",
          "16",
          "15",
          "14",
          "13",
          "12",
          "11",
          "10",
          "9",
          "8",
          "7",
          "6",
          "5",
          "4",
          "3",
          "2",
          "1",
        ],
      },
      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "BALCÓN IZQUIERDO",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) =>
              e?.seat == this.settings.id && e?.tribune == "BALCÓN IZQUIERDO"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([...__tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapBI(scbi);

    const scbc = $("#seat-map-bc").seatCharts({
      map: [
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaaaaaaaaa",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "O",
          "P",
          "Q",
          "R",
          "RR",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "BB",
          "CC",
        ],
        columns: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
        ],
      },

      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "BALCÓN CENTRO",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) => e?.seat == this.settings.id && e?.tribune == "BALCÓN CENTRO"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([...__tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapBC(scbc);

    const scbd = $("#seat-map-bd").seatCharts({
      map: [
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "aaaaaaaaaaaaaaaaa",
        "_aaaaaaaaaaaaaaaa",
      ],
      seats: {
        a: {
          price: 52000,
          description: "English Song Festival",
          classes: "front-seat", //your custom CSS class
        },
      },
      naming: {
        getLabel: function () {
          return "";
        },
        rows: [
          "O",
          "P",
          "Q",
          "R",
          "RR",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "BB",
          "CC",
          "DD",
        ],
        columns: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
        ],
      },

      click: function () {
        if (this.status() == "available") {
          const newSeat = {
            seat: this.settings.id,
            price: parseInt(this.settings.data.price),
            tribune: "BALCÓN DERECHO",
            state: null,
          };
          _tickets = [..._tickets, newSeat];
          setTickets(_tickets);
          return "selected";
        } else if (this.status() == "selected") {
          const item = _tickets.findIndex(
            (e) => e?.seat == this.settings.id && e?.tribune == "BALCÓN DERECHO"
          );
          if (item != -1) {
            let __tickets = _tickets;
            __tickets.splice(item, 1);
            setTickets([...__tickets]);
          }
          return "available";
        } else if (this.status() == "unavailable") {
          //seat has been already booked
          return "unavailable";
        } else {
          return this.style();
        }
      },
      focus: function () {
        let seatOver = $("#" + this.settings.id);
        seatOver.append(
          `<div className="seatTool" id="${this.settings.id}-tooltip">${this.settings.id}</div>`
        );
        return this.status();
      },
      blur: function () {
        let seatOver = $("#" + this.settings.id + "-tooltip");
        seatOver.remove();
        return this.status();
      },
    });
    setMapBD(scbd);

    data
      .filter((e) => e.place == "LUNETA IZQUIERDA")
      .map((e) => scli?.status(e.ticket, "unavailable"));
    data
      .filter((e) => e.place == "LUNETA CENTRO")
      .map((e) => sclc?.status(e.ticket, "unavailable"));
    data
      .filter((e) => e.place == "LUNETA DERECHA")
      .map((e) => scld?.status(e.ticket, "unavailable"));
    data
      .filter((e) => e.place == "BALCÓN IZQUIERDO")
      .map((e) => scbi?.status(e.ticket, "unavailable"));
    data
      .filter((e) => e.place == "BALCÓN CENTRO")
      .map((e) => scbc?.status(e.ticket, "unavailable"));
    data
      .filter((e) => e.place == "BALCÓN DERECHO")
      .map((e) => scbd?.status(e.ticket, "unavailable"));
  };

  const pay = async () => {
    if (!document || !name || !email) {
      Swal.fire({
        icon: "error",
        text: "Se debe ingresar el documento, nombre y correo",
      });
      return;
    }
    let items = [];
    for (const item of tickets) {
      const seat = item.seat.split("_");
      items.push({
        row: seat[0],
        column: seat[1],
        document,
        name,
        email,
        place: item.tribune,
        ticket: item.seat,
        price: item.price,
      });
    }
    let form = {
      tickets: items,
      total: tickets.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.price,
        0
      ),
    };
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${API}/payment/pay/manually`, form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
        },
      });
      if (data?.status) {
        _tickets = [];
        setDocument(null);
        setName(null);
        setEmail(null);
        setTickets([]);
        setInfo(data?.data?.data);
        window.open(
          `https://download.cantabriaticket.com/pdf/${data?.data?.id}`,
          "_blank"
        );
        Swal.fire({
          icon: "success",
          text: "Se creo la compra correctamente",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "No fue posible crear la compra",
        });
      }
    } catch (ex) {
      Swal.fire({
        icon: "error",
        text: ex.toString(),
      });
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Vender entradas" />
      <Container fluid={true}>
        <Row>
          <div className="col-6">
            <div className="row ">
              <div className="col-8">
                <div className="info-seatings row mt-5">
                  <div className="col-12 col-sm-4 info-group d-flex">
                    <div className="seat-colors c1 me-1"></div>
                    <p>Asientos disponibles</p>
                  </div>
                  <div className="col-12 col-sm-4 info-group d-flex">
                    <div className="seat-colors c2 me-1"></div>
                    <p>Asientos ocupados</p>
                  </div>
                  <div className="col-12 col-sm-4 info-group d-flex">
                    <div className="seat-colors c3 me-1"></div>
                    <p>Asientos seleccionados</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cont-seatings">
              <div className="">
                <div className="front">ESCENARIO</div>
              </div>
              <br />
              <h1>LUNETA IZQUIERDAD</h1>
              <div className="row">
                <div id="seat-map-li"></div>
              </div>
              <br />
              <h1>LUNETA CENTRO</h1>
              <div className="row">
                <div id="seat-map-lc"></div>
              </div>
              <br />
              <h1>LUNETA DERECHA</h1>
              <div className="row">
                <div id="seat-map-ld"></div>
              </div>
              <br />
              <h1>BALCÓN IZQUIERDAD</h1>
              <div className="row">
                <div id="seat-map-bi"></div>
              </div>
              <br />
              <h1>BALCÓN CENTRO</h1>
              <div className="row">
                <div id="seat-map-bc"></div>
              </div>
              <br />
              <h1>BALCÓN DERECHA</h1>
              <div className="row">
                <div id="seat-map-bd"></div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <h1>Entradas seleccionadas</h1>
            {tickets?.length > 0 ? (
              tickets.map((e) => (
                <div key={`${e.seat}-${e.tribune}`} className="card w-100 mb-3">
                  <div className="card-body">
                    <h5 className="card-title">{e.seat}</h5>
                    <p className="card-text">{e.tribune}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No se han seleccionado entradas para comprar</p>
            )}
            <div className="row">
              <div className="col">
                <h4>
                  Total: ${" "}
                  <NumberPoint
                    value={tickets.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue?.price,
                      0
                    )}
                  />
                </h4>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <label htmlFor="document" className="form-label">
                    Documento
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="document"
                    disabled={tickets?.length > 0 ? false : true}
                    onChange={(e) => setDocument(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    disabled={tickets?.length > 0 ? false : true}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    disabled={tickets?.length > 0 ? false : true}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12">
                {isLoading ? (
                  <div className="loader-box">
                    <Spinner
                      attrSpinner={{
                        className: "loader-2",
                        style: {
                          "border-left-color": "#74025a",
                          "border-right-color": "#74025a",
                        },
                      }}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success float-end"
                    disabled={tickets?.length > 0 ? false : true}
                    onClick={pay}
                  >
                    Comprar
                  </button>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BuyTicket;
