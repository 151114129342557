import React, { Fragment, useEffect, useState } from "react";
import { Col, Card } from "reactstrap";
import DataTable from "react-data-table-component";
import { Spinner } from "../../../AbstractElements";
import axios from "axios";
import { API } from "../../../api";

const Table = () => {
  const tableColumns = [
    {
      name: "Documento",
      selector: (row) => `${row.document}`,
      sortable: true,
      center: false,
    },
    {
      name: "Nombre",
      selector: (row) => `${row.name}`,
      sortable: true,
      center: true,
    },
    {
      name: "Correo",
      selector: (row) => `${row.email}`,
      sortable: true,
      center: true,
    },
    {
      name: "# Celular",
      selector: (row) => `${row.cellphone}`,
      sortable: true,
      center: true,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(`${API}/dashboard/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            setInfo(data.data);
          } else {
            alert(data.error);
          }
          setIsLoading(false);
        });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          {isLoading ? (
            <div className="loader-box d-flex justify-center align-items-center">
              <Spinner attrSpinner={{ className: "loader-2" }} />
            </div>
          ) : (
            <DataTable
              data={info}
              columns={tableColumns}
              striped={true}
              center={true}
              pagination
            />
          )}
        </Card>
      </Col>
    </Fragment>
  );
};

export default Table;
