import React, { Fragment } from "react";
import { Container, Row, Col, Table} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";

import DataTable from "./DataTable";

const Purchases = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Ventas" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <Col className="col-xxl-4 col-sm-6 box-col-6">
          </Col>
          <DataTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Purchases;
