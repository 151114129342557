import React, { Fragment, useEffect, useState } from "react";
import { Col, Card } from "reactstrap";
import { NavLink, Nav, NavItem } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Spinner } from "../../../AbstractElements";
import axios from "axios";
import { API } from "../../../api";

const Table = () => {
  const tableColumns = [
    {
      name: "Lugar",
      selector: (row) => `${row.place}`,
      sortable: true,
      center: false,
    },
    {
      name: "Asiento",
      selector: (row) => `${row.ticket}`,
      sortable: true,
      center: true,
    },
    {
      name: "Usuario",
      selector: (row) => `${row.document} ${row.name}`,
      sortable: true,
      center: true,
    },
    {
      name: "estado",
      selector: (row) => `${row.state}`,
      sortable: true,
      center: true,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState([]);
  const [place, setPlace] = useState("All");

  useEffect(() => {
    getData(place);
  }, []);

  useEffect(() => {
    getData(place);
  }, [place]);

  const getData = async (place) => {
    try {
      setIsLoading(true);
      await axios
        .get(`${API}/dashboard/tickets/${place}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            setInfo(data.data);
          } else {
            alert(data.error);
          }
          setIsLoading(false);
        });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Fragment>
      <Col sm="12">
        <Fragment>
          <div className="text-center">
            <Nav tabs className="search-list" id="top-tab" role="tablist">
              <NavItem>
                <NavLink
                  className={place === "All" ? "active" : ""}
                  onClick={() => {
                    setPlace("All");
                  }}
                >
                  Todos
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "LUNETA IZQUIERDA" ? "active" : ""}
                  onClick={() => {
                    setPlace("LUNETA IZQUIERDA");
                  }}
                >
                  LUNETA IZQUIERDA
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "LUNETA CENTRO" ? "active" : ""}
                  onClick={() => {
                    setPlace("LUNETA CENTRO");
                  }}
                >
                  LUNETA CENTRO
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "LUNETA DERECHA" ? "active" : ""}
                  onClick={() => {
                    setPlace("LUNETA DERECHA");
                  }}
                >
                  LUNETA DERECHA
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "BALCÓN IZQUIERDO" ? "active" : ""}
                  onClick={() => {
                    setPlace("BALCÓN IZQUIERDO");
                  }}
                >
                  BALCÓN IZQUIERDO
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "BALCÓN CENTRO" ? "active" : ""}
                  onClick={() => {
                    setPlace("BALCÓN CENTRO");
                  }}
                >
                  BALCÓN CENTRO
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem>
                <NavLink
                  className={place === "BALCÓN DERECHO" ? "active" : ""}
                  onClick={() => {
                    setPlace("BALCÓN DERECHO");
                  }}
                >
                  BALCÓN DERECHO
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
            </Nav>
          </div>
        </Fragment>
        <Card>
          {isLoading ? (
            <div className="loader-box d-flex justify-center align-items-center">
              <Spinner attrSpinner={{ className: "loader-2" }} />
            </div>
          ) : (
            <DataTable
              data={info}
              columns={tableColumns}
              striped={true}
              center={true}
              pagination
            />
          )}
        </Card>
      </Col>
    </Fragment>
  );
};

export default Table;
