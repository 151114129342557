import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';

const MonthlyProfits = ({info}) => {
  const MonthlyProfitsChartData = {
    series: [info?.tickets?.count, info?.booking?.count, info?.tickets_emergency?.count, info?.tickets_total?.count-(info?.tickets?.count+info?.booking?.count+info?.tickets_emergency?.count)],
    options: {
      labels: ["Entradas vendidas", "Entradas reservadas", "Entradas de emergencia", "Entradas disponibles",],
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                label: `${info?.tickets_total?.count}`,
                formatter: () => "# de entradas",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A", "var(--theme-default)", "#808080", "#ffaa05"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardHeader className='card-no-border'>
        <H5>Ocupación</H5>
        <span className='f-light f-w-500 f-14'>(Cantidad de entradas gestionadas)</span>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className='monthly-profit'>
          <ReactApexChart type='donut' height={300} series={MonthlyProfitsChartData.series} options={MonthlyProfitsChartData.options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default MonthlyProfits;
