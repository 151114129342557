import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Breadcrumbs, Spinner } from "../../../AbstractElements";

import WidgetsWrapper from "./WidgetsWraper";
import TotalBalance from "./TotalBalance";
import MonthlyProfits from "./MonthlyProfits";
import AllCampaigns from "./AllCampaigns";
import { API } from "./../../../api";
import { useNavigate } from "react-router";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";

const Dashboard = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState();
  const [user, setUser] = useState();

  const buy = {
    title: "# Entradas validadas",
    gros: 100,
    total: info?.tickets_attended?.count,
    color: "primary",
    icon: "fill-builders",
  };

  useEffect(() => {
    setUser(jwt_decode(localStorage?.authenticated));
    getData();
  }, []);

  const getData = async () => {
    try {
      await axios
        .get(`${API}/dashboard/payment/info`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        })
        .then(({ data }) => {
          if (data.status) {
            setInfo(data.data);
          } else {
            alert(data.error);
          }
          setIsLoading(false);
        });
    } catch (error) {
      alert(error);
      if (error?.code == "ERR_BAD_REQUEST") {
        localStorage.removeItem("authenticated");
        window.location.href = `/`;
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" parent="Menú" title="Dashboard" />
      <Container fluid={true}>
        {isLoading ? (
          <div className="loader-box d-flex justify-center align-items-center">
            <Spinner attrSpinner={{ className: "loader-2" }} />
          </div>
        ) : (
          <Row className="widget-grid">
            {user?.role == "ADMIN" ? (
              <Col className="col-xxl-4 col-sm-6 box-col-6">
                <TotalBalance info={info} />
                <Widgets1 data={buy} />
              </Col>
            ) : null}

            <WidgetsWrapper info={info} />
            <Col className="col-xxl-4 col-sm-4 box-col-12">
              <MonthlyProfits info={info} />
            </Col>
            <Col className="col-xxl-8 col-sm-8 box-col-12">
              <AllCampaigns info={info} />
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default Dashboard;
