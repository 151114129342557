import React, { Fragment, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { QrScanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import Swal from "sweetalert2";

import { Breadcrumbs, Spinner } from "./../../AbstractElements";
import { API } from "../../api";

const ReadQR = () => {
  const [id, setId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [info, setInfo] = useState();
  const [document, setDocument] = useState();

  const getInfo = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${API}/dashboard/getticket/valid`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        }
      );
      if (!data?.status) {
        setResponse(data?.status);
        setId(null);
      } else {
        setInfo(data?.data);
      }
    } catch (ex) {
      Swal.fire({
        icon: "error",
        text: ex.toString(),
      });
    }
    setIsLoading(false);
  };

  const read = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${API}/dashboard/ticket/valid`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        }
      );
      setResponse(data?.status);
      setInfo(data?.data);
    } catch (ex) {
      Swal.fire({
        icon: "error",
        text: ex.toString(),
      });
    }
    setIsLoading(false);
  };

  const validBydocument = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${API}/dashboard/ticket/valid/bydocument`,
        { document },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authenticated")}`,
          },
        }
      );
      setResponse(data?.status);
      setInfo(data?.data);
    } catch (ex) {
      Swal.fire({
        icon: "error",
        text: ex.toString(),
      });
    }

    setDocument(null);
    setId(null);
    setIsLoading(false);
  };

  const newScan = () => {
    setId(null);
    setResponse(null);
    setInfo(null);
  };

  const Loading = () => (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-center align-items-center flex-column"
    >
      <div className="loader-box">
        <Spinner
          attrSpinner={{
            className: "loader-2",
            style: {
              width: 100,
              height: 100,
              "border-left-color": "#74025a",
              "border-right-color": "#74025a",
            },
          }}
        />
      </div>
      <div className="text-center">
        <h3 style={{ color: "#74025a" }}>Validando entrada...</h3>
      </div>
    </div>
  );

  const Info = () => (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-center align-items-center flex-column"
    >
      <div className="loader-box">
        <i
          style={{ color: "#130976", fontSize: 140 }}
          className="fa fa-info-circle"
        ></i>
      </div>
      <div className="text-center">
        <h3 style={{ color: "#130976" }}>Información de la entrada</h3>
        <p>
          <b>Documento:</b> {info?.document}
        </p>
        <p>
          <b>Nombre:</b> {info?.name}
        </p>
        <p>
          <b>Entrada:</b> {info?.place} {info?.ticket}
        </p>

        <button type="button" onClick={read} className="btn btn-success">
          Validar entrada
        </button>
        <button style={{marginLeft: 10}} type="button" onClick={newScan} className="btn btn-outline-dark">
          Cancelar
        </button>
      </div>
    </div>
  );

  const Success = () => (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-center align-items-center flex-column"
    >
      <div className="loader-box">
        <i
          style={{ color: "#aac14e", fontSize: 140 }}
          className="fa fa-check-circle"
        ></i>
      </div>
      <div className="text-center">
        <h3 style={{ color: "#aac14e" }}>Entrada válida</h3>
        <p>
          <b>Documento:</b> {info?.document}
        </p>
        <p>
          <b>Nombre:</b> {info?.name}
        </p>
        <p>
          <b>Entrada:</b> {info?.place} {info?.ticket}
        </p>

        <button type="button" onClick={newScan} className="btn btn-success">
          Leer nuevo QR
        </button>
      </div>
    </div>
  );

  const Fail = () => (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-center align-items-center flex-column"
    >
      <div className="loader-box">
        <i
          style={{ color: "#f79c32", fontSize: 140 }}
          className="fa fa-remove"
        ></i>
      </div>
      <div className="text-center">
        <h4 style={{ color: "#f79c32" }}>La entrada ya se usó o no existe</h4>
        <button type="button" onClick={newScan} className="btn btn-warning">
          Leer nuevo QR
        </button>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Validar entradas" />
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col className="col-xxl-4 col-sm-4 ">
            {isLoading ? <Loading /> : null}
            {info && response == null ? <Info /> : null}
            {response == true ? (
              <Success />
            ) : response == false ? (
              <Fail />
            ) : null}
            {!isLoading && response == null && info == null ? (
              <>
                <h3>Escanea el código QR o ingresa el documento registrado</h3>
                <QrScanner
                  onDecode={(result) => {
                    if (!id) {
                      setId(result);
                      getInfo(result);
                    }
                  }}
                  onError={(error) => console.log(error?.message)}
                />
                <br />
                <p>//</p>
                <br />
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    value={document}
                    onChange={(e) => setDocument(e.target.value)}
                    placeholder="Documento"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button
                    class="btn btn-outline-success"
                    type="button"
                    id="button-addon2"
                    onClick={validBydocument}
                  >
                    Validar
                  </button>
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ReadQR;
