export const MENUITEMS = [
  {
    menutitle: 'Menú',
    menucontent: 'Dashboards,Widgets',
    Items: [
      
      { path: `/dashboard`, icon: "home", title: "Inicio", type: "link" },
      { path: `/readqr`, icon: "to-do", title: "Validar entrada", type: "link" },
      { path: `/buy`, icon: "builders", title: "Vender entrada", type: "link" },
      { path: `/tickets`, icon: "widget", title: "Entradas", type: "link" },
      { path: `/purchases`, icon: "ecommerce", title: "Ventas", type: "link" },
      { path: `/users`, icon: "user", title: "Usuarios", type: "link" },
      { path: `/reserves`, icon: "form", title: "Reservados", type: "link" },
      { path: `/discounts`, icon: "icons", title: "Descuentos", type: "link" },
      { path: `/event`, icon: "blog", title: "Evento", type: "link" },
    ],
  },
];
