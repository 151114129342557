import React from "react";
import { Col, Row } from "reactstrap";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";

const WidgetsWrapper = ({ info }) => {
  const buy = {
    title: "# Compras realizadas",
    gros: 100,
    total: info?.payments?.count,
    color: "primary",
    icon: "cart",
  };

  const ticket = {
    title: "# Entradas vendidas",
    gros: 100,
    total: info?.tickets?.count,
    color: "secondary",
    icon: "fill-builders",
  };

  const users = {
    title: "# Usuarios registrados",
    gros: 100,
    total: info?.users?.count,
    color: "success",
    icon: "user-visitor",
  };

  const booking = {
    title: "# Reservas registradas",
    gros: 100,
    total: info?.booking?.count,
    color: "warning",
    icon: "orders",
  };

  const discount = {
    title: "# Descuentos registrados",
    gros: 100,
    total: info?.discounts?.count,
    color: "primary",
    icon: "customers",
  };

  const ticketsEnabled = {
    title: "# Entradas disponibles",
    gros: 100,
    total: info?.tickets_total?.count-(info?.tickets?.count+info?.booking?.count),
    color: "defaul",
    icon: "course-1",
  };

  return (
    <>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="12">
            <Widgets1 data={buy} />
          </Col>
          <Col xl="12">
            <Widgets1 data={users} />
          </Col>
        </Row>
      </Col>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="12">
            <Widgets1 data={ticket} />
          </Col>
          <Col xl="12">
            <Widgets1 data={booking} />
          </Col>
        </Row>
      </Col>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="12">
            <Widgets1 data={ticketsEnabled} />
          </Col>
          <Col xl="12">
            <Widgets1 data={discount} />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default WidgetsWrapper;
