import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { DailyDropdown } from "../../../Constant";
import DropdownCommon from "../../Common/Dropdown";

const AllCampaigns = ({ info }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let _data = [];
    const colors = [
      "facebook",
      "instagram",
      "pinterest",
      "twitter",
      "you-tube",
      "instagram",
    ];
    info?.filter_tickets?.map((e, i) => {
      const place = e._id.state;
      const buy = e.count;
      const booking =
        info?.filter_tickets_booking?.find((i) => i._id.state == place)
          ?.count ?? 0;
      const count =
        info?.tickets_total?.places?.find((i) => i.name == place)?.count ?? 0;

      _data.push({
        place,
        buy,
        booking,
        count,
        enabled: count - (buy + booking),
        color: colors[i],
      });
    });
    setData(_data);
  }, []);

  return (
    <Card>
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 className="m-0">Ocupación por ubicación</H5>
          <div className="card-header-right-icon">
            <DropdownCommon
              dropdownMain={{ className: "icon-dropdown", direction: "start" }}
              options={DailyDropdown}
              iconName="icon-more-alt"
              btn={{ tag: "span" }}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0 campaign-table">
        <div className="recent-table table-responsive currency-table">
          <Table>
            <thead>
              <tr>
                <th className="f-light">Ubicación</th>
                <th className="f-light"># Compradas</th>
                <th className="f-light"># Reservadas</th>
                <th className="f-light"># Cantidad</th>
                <th className="f-light"># Disponibles</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td className={`border-icon ${item.color}`}>
                    <div>{item.place}</div>
                  </td>
                  <td>{item.buy}</td>
                  <td>{item.booking}</td>
                  <td>{item.count}</td>
                  <td>{item.enabled}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AllCampaigns;
