import React, { createContext, useState, useContext } from "react";

export const AppContext = createContext();
export const useMyContext = () => useContext(AppContext);

const Provider = ({ children }) => {
  const [tickets, setTickets] = useState([]);
  return (
    <AppContext.Provider value={[tickets, setTickets]}>
      {children}
    </AppContext.Provider>
  );
};

export default Provider;
